import React from "react";
import { User as FirebaseUser } from "firebase/auth";
import { collection, getFirestore, query, where, getDocs, limit } from 'firebase/firestore';
import logo from "./logo.png";
import {
  Authenticator,
  Entity,
  FirebaseCMSApp,
  FirestoreTextSearchController,
  NavigationBuilder,
  NavigationBuilderProps
} from "@camberi/firecms";
import "typeface-rubik";
import "typeface-space-mono";
import { EmailAuthProvider } from "firebase/auth";
import { buildCategory } from './schemas/categories_schema';
import { buildCategoryVideo } from './schemas/categories_videos_schema';
import { buildBanner } from "./schemas/banners_schema";
import { buildSpecialty } from "./schemas/specialties_schema";
import { User, userSchema } from "./schemas/users_schema";
import { buildTag } from "./schemas/tags_schema";
import { buildPost } from "./schemas/all_posts.schema";
import { buildPage } from "./schemas/pages_schema";
import { firebaseConfig } from "./env";

const DEFAULT_SIGN_IN_OPTIONS = [
  EmailAuthProvider.PROVIDER_ID
];

function removeAccents(str): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

function stringSearch(str: string, whiteSpaces?: boolean): string {
  str = str.trim();
  var noTildes: string = removeAccents(str);
  let regexp: RegExp = /[^a-zA-Z0-9]/g;
  if (whiteSpaces === true) {
    regexp = /[^a-zA-Z0-9 ]/g;
  }
  let search: string = noTildes.replace(regexp, '').toLocaleLowerCase();
  search = search.replace(/^\s+|\s+$|\s+(?=\s)/g, '');
  return search;
};

function getPosts(path, searchString): Promise<readonly string[]> {
  return new Promise(async (resolve, reject) => {
    const _collection = collection(getFirestore(), path);
    let _query;
    let docs = [];
    let search = stringSearch(searchString, true);
    if (search && String(search).length > 3) {
      if (path === 'posts') {
        _query = query(_collection, where("search", "array-contains", search), limit(20));
      } else if (path === 'banners') {
        _query = query(_collection, where("search", "array-contains", search), limit(20));
      }
      const resp = await getDocs(_query);
      docs = resp.docs.map(e => e.id);
    }
    resolve(docs);
  })
};

const textSearchController: FirestoreTextSearchController =
  ({ path, searchString }) => {
    return getPosts(path, searchString);
  };

export default function App() {
  const navigation: NavigationBuilder = async ({
    user,
    authController,
  }: NavigationBuilderProps) => {
    return {
      collections: [
        buildBanner,
        buildCategory,
        buildCategoryVideo,
        buildSpecialty,
        buildTag,
        buildPost,
        buildPage
      ],
    };
  };

  const myAuthenticator: Authenticator<FirebaseUser> = async ({
    user,
    authController,
    dataSource
  }) => {
    let resp: Entity<User>;
    try {
      resp = await dataSource.fetchEntity({ path: "users", schema: userSchema, entityId: user.uid });
    } catch (e) {
      console.error();
    }
    const data = { type: resp && resp.values && resp.values.type ? resp.values.type : undefined, status: resp && resp.values && resp.values.status ? resp.values.status : undefined }
    if (!!data.status && data.status === 'active' && !!data.type && (resp.values.type === 'administrador' || resp.values.type === 'editor' || resp.values.type === 'videos')) {
      const userData = await Promise.resolve({
        roles: [resp.values.type],
      });
      authController.setExtra(userData);
    } else {
      throw Error("User is inactive or have not permissions!");
    }
    return true;
  };

  return (
    <FirebaseCMSApp
      name={"Conectimed CMS"}
      authentication={myAuthenticator}
      navigation={navigation}
      signInOptions={DEFAULT_SIGN_IN_OPTIONS}
      primaryColor={"#2184c5"}
      logo={logo}
      secondaryColor={"#0e518b"}
      locale={"es"}
      textSearchController={textSearchController}
      firebaseConfig={firebaseConfig}
      allowSkipLogin={false}
    />
  );
}
