import { buildCollection, buildEntityCallbacks, buildProperty, buildSchema, EntityOnDeleteProps, EntityOnSaveProps, EntityReference, PropertiesOrBuilder } from "@camberi/firecms";
import moment from "moment";

export type Post = {
  type: string;
  webinar_type: string;
  title: string;
  is_outstanding: boolean;
  content: string;
  excerpt: string;
  image: string;
  publication_date: Date;
  status: string;
  category: EntityReference;
  specialties: EntityReference[];
  tags: EntityReference[];
  fecha_de_inicio: Date;
  fecha_fin: Date;
  video_url: string;
  access_code: string;
  search: string[];
  slug: string;
  nameStr: string;
  date: Date;
  modified: Date;
};

const postProperties: PropertiesOrBuilder<Post> = {
  type: {
    title: "Tipo de entrada",
    validation: { required: true },
    dataType: "string",
    config: {
      enumValues: {
        post: "Entrada normal",
        video: "Video",
        curso_o_congreso: "Curso o congreso"
      },
    },
  },
  title: {
    title: "Título",
    validation: { required: true },
    dataType: "string",
  },
  content: {
    title: "Contenido",
    validation: { required: false },
    dataType: "string",
    config: {
      markdown: true,
      multiline: true
    }
  },
  image: buildProperty({
    title: "Imágen",
    dataType: "string",
    validation: {
      required: true
    },
    config: {
      storageMeta: {
        mediaType: "image",
        storagePath: "images",
        acceptedFiles: ["image/*"],
        storeUrl: true
      },
    },
  }),
  category: ({ values }) => {
    //console.log(values && values.category && values.category.id && values.category.path && values.type && values.type === 'video' && values.category.id === "6748" && values.category.path === "categories_video" ? true : false);
    return ({
      dataType: "reference",
      path: values && values.type === 'video' ? "categories_video" : "categories",
      title: "Categoría",
      disabled: values && (values.type === 'post' || values.type === 'video') ? false : { hidden: true },
      validation: values && (values.type === 'post' || values.type === 'video') ? {
        required: true,
      } : { required: false },
      previewProperties: ["name"]
    })
  },
  webinar_type: ({ values }) => {
    if (values && values.category && values.category.id && values.category.path && values.type && values.type === 'video' && values.category.id === "6748" && values.category.path === "categories_video") {
      return {
        title: "Tipo de Webinar",
        validation: { required: true },
        dataType: "string",
        config: {
          enumValues: {
            youtube: "Youtube",
            click_meeting: "Click Meeting",
            zoom_meeting: "Zoom Meeting"
          },
        },
      }
    } else {
      return {
        title: "Tipo de Webinar",
        disabled: { hidden: true },
        validation: { required: false },
        dataType: "string",
        config: {
          enumValues: {
            youtube: "Youtube",
            click_meeting: "Click Meeting",
            zoom_meeting: "Zoom Meeting"
          },
        },
      }
    }
  },
  video_url: ({ values }) => {
    if (values && values.type === 'video') {
      if (values && values.category && values.category.id && values.category.path && values.type && values.category.id === "6748" && values.category.path === "categories_video" && values.webinar_type === 'click_meeting') {
        return {
          title: "ID de sala",
          dataType: "string",
          disabled: false,
          validation: {
            url: false,
            required: true,
          }
        }
      } else if (values && values.category && values.category.id && values.category.path && values.type && values.category.id === "6748" && values.category.path === "categories_video" && values.webinar_type === 'zoom_meeting') {
        return {
          title: "ID de reunión",
          dataType: "string",
          disabled: false,
          validation: {
            url: false,
            required: true,
          }
        }
      } else {
        return {
          title: "URL de video",
          dataType: "string",
          disabled: false,
          validation: {
            url: true,
            required: true,
          }
        }
      }
    } else {
      return {
        title: "URL de video",
        dataType: "string",
        disabled: { hidden: true },
        validation: { required: false }
      }
    }
  },
  access_code: ({ values }) => ({
    title: "Código de acceso",
    disabled: values && values.category && values.category.id && values.category.path && values.type && values.category.id === "6748" && values.category.path === "categories_video" && values.webinar_type === 'zoom_meeting' ? false : { hidden: true },
    validation: { required: values && values.category && values.category.id && values.category.path && values.type && values.category.id === "6748" && values.category.path === "categories_video" && values.webinar_type === 'zoom_meeting' ? true : false },
    dataType: "string",
  }),
  specialties: ({ values }) => ({
    dataType: "array",
    title: "Especialidades",
    disabled: values && (values.type === 'post') ? false : { hidden: true },
    validation: values && (values.type === 'post') ? {
      required: true,
    } : { required: false },
    description:
      "Selecciona las especialidades a las que pertenece ésta entrada",
    of: {
      dataType: "reference",
      path: "specialties",
      previewProperties: ["name"]
    },
  }),
  tags: {
    dataType: "array",
    title: "Tags",
    validation: {
      required: true
    },
    description:
      "Selecciona los tags a las que pertenece ésta entrada",
    of: {
      dataType: "reference",
      path: "tags",
      previewProperties: ["title"]
    },
  },
  fecha_de_inicio: ({ values }) => ({
    title: "Fecha de inicio",
    dataType: "timestamp",
    disabled: values && (values.type === 'video' || values.type === 'curso_o_congreso') ? false : { hidden: true },
    validation: values && values.type === 'curso_o_congreso' ? {
      required: true,
    } : { required: false },
  }),
  fecha_fin: ({ values }) => ({
    title: "Fecha fin",
    dataType: "timestamp",
    disabled: values && (values.type === 'video' || values.type === 'curso_o_congreso') ? false : { hidden: true },
    validation: values && values.type === 'curso_o_congreso' ? {
      required: true,
    } : { required: false }
  }),
  publication_date: {
    columnWidth: 350,
    title: "Fecha de publicación",
    dataType: "timestamp",
    // validation: { min: moment().toDate() },
    description: "Fecha en la cual la entrada se publicará"
  },
  status: {
    title: "Status",
    validation: { required: true },
    dataType: "string",
    config: {
      enumValues: {
        active: "Activo",
        inactive: "Inactivo",
        pending: "Pendiente",
      },
    },
  },
  is_outstanding: {
    dataType: "boolean",
    title: "Destacado",
    validation: { required: false }
  },
  excerpt: {
    dataType: "string",
    disabled: {
      hidden: true
    }
  },
  search: {
    dataType: "array",
    disabled: {
      hidden: true
    }
  },
  slug: {
    dataType: "string",
    disabled: {
      hidden: true
    }
  },
  nameStr: {
    dataType: "string",
    disabled: {
      hidden: true
    }
  },
  date: {
    dataType: "timestamp",
    disabled: {
      hidden: true
    },
    validation: {
      required: true
    }
  },
  modified: {
    dataType: "timestamp",
    disabled: {
      hidden: true
    }
  },
}

export const postsSchema = buildSchema<Post>({
  name: "Entrada",
  description: "Entradas de la plataforma",
  defaultValues: {
    date: new Date(),
    fecha_de_inicio: new Date(),
    status: "active",
    is_outstanding: false,
    type: "post",
    tipo_de_video: "regular",
    video_category: "entrevista",
    webinar_type: "youtube"
  },
  properties: postProperties
});

export const postsCallbacks = buildEntityCallbacks({
  onPreSave: ({
    schema,
    path,
    entityId,
    values,
    status
  }) => {
    if (!!!values.publication_date) {
      values.publication_date = moment().toDate();
      values.status = 'active';
    } else {
      let current_date = moment().toDate();
      let publication_date = values.publication_date;
      if (current_date <= publication_date) {
        values.status = 'pending';
      }
    }
    values.modified = new Date();
    values.nameStr = '';
    values.search = [];
    values.slug = '';
    values.excerpt = '';
    return values;
  },
  onSaveSuccess: (props: EntityOnSaveProps<Post>) => {
    //console.log("onSaveSuccess", props);
  },
  onSaveFailure: (props: EntityOnSaveProps<Post>) => {
    // console.log("onSaveFailure", props);
  },
  onPreDelete: ({
    schema,
    path,
    entityId,
    entity,
    context
  }: EntityOnDeleteProps<Post>
  ) => {
    // if (context.authController.user)
    //   throw Error("Product deletion not allowed");
  },
  onDelete: (props: EntityOnDeleteProps<Post>) => {
    //console.log("onDelete", props);
  },
});

export const buildPost = buildCollection({
  textSearchEnabled: true,
  path: "posts",
  defaultSize: "xs",
  schema: postsSchema,
  name: "Entradas",
  initialSort: ["publication_date", "desc"],
  inlineEditing: false,
  properties: ["title", "type", "image", "is_outstanding", "status", "publication_date"],
  group: "Contenido",
  exportable: false,
  pagination: true,
  description: "Crear entradas para Conectimed (Entradas, videos, cursos ó congresos)",
  permissions: ({ authController }) => ({
    edit: authController.extra.roles.includes("administrador") || authController.extra.roles.includes("editor") || authController.extra.roles.includes("videos"),
    create: authController.extra.roles.includes("administrador") || authController.extra.roles.includes("editor") || authController.extra.roles.includes("videos"),
    delete: authController.extra.roles.includes("administrador") || authController.extra.roles.includes("editor") || authController.extra.roles.includes("videos"),
  }),
  callbacks: postsCallbacks
});