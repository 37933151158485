// Firebase Config
export const firebaseConfig = {
  apiKey: "AIzaSyDNse9Pg_XQHuA5Rq9Kl7mK2xj7EmS1-Ps",
  authDomain: "conectimed-9d22c.firebaseapp.com",
  databaseURL: "https://conectimed-9d22c.firebaseio.com",
  projectId: "conectimed-9d22c",
  storageBucket: "conectimed-9d22c.appspot.com",
  messagingSenderId: "373046668834",
  appId: "1:373046668834:web:2e90f93e4132ab902f247b",
  measurementId: "G-4HZE3BRY9V",
  api: 'https://us-central1-conectimed-9d22c.cloudfunctions.net/'
};

export const firebaseConfigProd = {
  apiKey: 'AIzaSyBHws3ZggcZ15HEI9C-NtFtNNwMNiKnF5A',
  authDomain: 'conectimed-production.firebaseapp.com',
  databaseURL: 'https://conectimed-production.firebaseio.com',
  projectId: 'conectimed-production',
  storageBucket: 'conectimed-production.appspot.com',
  messagingSenderId: '1016750905327',
  appId: '1:1016750905327:web:f1d7cba40dd5780ab5481a',
  measurementId: 'G-W5Q4GYW8D5',
  api: 'https://us-central1-conectimed-production.cloudfunctions.net/'
};