import { buildSchema } from "@camberi/firecms";

export type User = {
  status: string;
  type: string;
};

export const userSchema = buildSchema<User>({
  name: "User",
  properties: {
    status: {
      dataType: "string",
      disabled: {
        hidden: true
      }
    },
    type: {
      dataType: "string",
      disabled: {
        hidden: true
      }
    }
  },
});